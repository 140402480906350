import axios from '@/plugins/axios';

export default {
  getOrganisationAssetReports(config = {}) {
    return axios.get('/v1/organisation/asset-reports', config);
  },
  getOrganisationAssetReport(assetReportId, config = {}) {
    return axios.get(`/v1/organisation/asset-reports/${assetReportId}`, config);
  },
  createOrganisationAssetReport(data, config = {}) {
    return axios.post('/v1/organisation/asset-reports', data, config);
  },
  updateOrganisationAssetReport(assetReportId, data, config = {}) {
    return axios.put(`/v1/organisation/asset-reports/${assetReportId}`, data, config);
  },
  deleteOrganisationAssetReport(assetReportId, config = {}) {
    return axios.delete(`/v1/organisation/asset-reports/${assetReportId}`, config);
  },
  createOrganisationAssetReportMedia(assetReportId, data, config = {}) {
    return axios.post(`/v1/organisation/asset-reports/${assetReportId}/media`, data, config);
  },
  deleteOrganisationAssetReportMedia(assetReportId, mediaId, config = {}) {
    return axios.delete(`/v1/organisation/asset-reports/${assetReportId}/media/${mediaId}`, config);
  },
  exportOrganisationAssetReports(config = {}) {
    const mergedConfig = {
      ...config,
      params: {
        ...config.params,
        export: 'csv',
      },
    };

    return this.getOrganisationAssetReports(mergedConfig);
  },
};
